.shadow-effect {
    background: #FA4D3F;
    padding: 20px 30px;
    width: 390px;
    height:230px;

    text-align: center;
    border: 1px solid #FA4D3F;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
  }
  
  #shadow-effect p {
    font-family: inherit;
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 17px 0;
    font-weight: 300;
    color: #fff;
  }
  .img-circle {
    position: absolute;
    
    display: table;
    left: 65%;
    bottom:15%;
    max-width: 210px;
    min-height:auto;
    transform-style: preserve-3d;
    margin: 0 auto 17px;
  }
  #customer-testimonoals {
    margin-top: 6%;
  }

  .testimonial-name {
    margin: -40px auto 0 ;
    display: table;
     position: relative;
    left: -20%;
    width: auto;
    /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
    background: #1E1E1E;
    padding: 9px 35px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 9px 18px rgba(0,0,0,0.12), 0px 5px 7px rgba(0,0,0,0.5);
  }
  #customer-testimonoals .item {
    text-align: center;
    padding: 20px 0 20px 0;
    margin-bottom: 40px;
    margin-top: 40px;
    opacity: 0.2;
    color: #fff;
    transform: scale3d(0.5, 0.5, 1);
    transition: all 0.3s ease-in-out;
  }
  #customer-testimonoals .owl-item.active.center .item {
  
    opacity: 1;
    transform: scale3d(1.2, 1.2, 1);
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
  }
  .owl-carousel .owl-dots .owl-dot span{ 
    /* background: #eaa400; */
    background: #56423D;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;
  } 