.top-border {
    border: 1px solid;
    color: #D9D9D9;
    margin-top: -80px;
}

.img-mission {
    width: 600px; 
    height: 360px; 
    background-size: cover;
    
}