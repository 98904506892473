.slick-prev:before,
.slick-next:before {
    color: white;
    font-size: 16px;
}

.img-carousel {
    margin-left: 20px;
    padding-right: 20px;
    width: 330px;
    height: 390px;
}

@media (min-width: 768px) {

    .slick-prev:before,
    .slick-next:before {
        color: black;
        font-size: 20px;
    }
}

@media (min-width: 1200px) {

    .slick-prev:before,
    .slick-next:before {
        color: black;
        font-size: 40px;
    }

    .img-carousel {
        width: 900px;
        height: 760px;
    }

    .slick-prev{
        left: 25px;
    }

    .slick-next{
        right: 25px;
    }

    .slick-dots li {
        margin-bottom: 10px;
    }

    .slick-dots li button {
        margin-bottom: 10px;
        width: 20px;
        height: 20px;
    }
}

.slider .slick-arrow.slick-prev {
    left: 0;
}

.slider .slick-arrow.slick-next {
    right: 0;
}

.slider .slick-arrow {
    position: absolute;
    top: 50%;
    margin: -20px 0px 0px 20px;
    z-index: 10;
    font-size: 0;
    width: 20px;
    height: 20px;
}

.slick-dots {
    bottom: 100px;
}

.slick-dots li {
    margin-right: 14px;
}

.slick-dots li button:before {
    font-size: 33px;
    line-height: 20px;
    color: #D1240C;
}

.slick-dots li.slick-active button:before {
    color:#D1240C !important;
}